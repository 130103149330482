












































































































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Questions from "../components/Questions.vue";
import InformationBox from "../components/InformationBox.vue";
import levenshtein from "js-levenshtein";
import deepEqual from "deep-equal";
import { getMissingMandatoryAnswers } from "../conditions";

@Component({
  components: {
    Questions,
    InformationBox,
  },
})
export default class AreaEdit extends Vue {
  @Prop() public id!: number;
  @Prop() public areaId!: string;

  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = true;
  public answersCompleted: boolean = false;

  public area: any = {
    id: 0,
    name: "",
    address: "",
  };
  public areaPresence: any = null;

  public hourOptions = [];
  public hasAreaPreferenceChanged: boolean = false;
  public checkAreaPreferenceChanged: boolean = false;
  public tab: string = "general";
  public areaName: string = "";
  public answers: any = {};
  public buildingAnswers: any = {};
  public initialAnswers: any = null;
  public missingAnswers: any = [];
  public questions: any = [];

  @Watch("areaPresence", { immediate: true, deep: true })
  public checkAreaPreferencesChanges() {
    if (this.areaPresence === null) {
      return;
    }
    if (this.checkAreaPreferenceChanged === false) {
      this.checkAreaPreferenceChanged = true;
      return;
    }
    this.hasAreaPreferenceChanged = true;
  }

  public refreshMissingAnswers() {
    this.missingAnswers = getMissingMandatoryAnswers(
      this.questions,
      { ...this.answers, ...this.buildingAnswers },
      "area"
    );
  }

  public async refresh() {
    this.$store
      .dispatch("loadArea", {
        areaId: this.areaId,
        params: {
          recursive: true,
        },
      })
      .then((response: any) => {
        this.area = response;
        this.areaName = this.area.name;
        this.loading = false;
        this.updateTab();
      })
      .catch((error: any) => {
        this.loading = false;
        this.globalError(error);
      });
  }

  public created() {
    // generate hour options
    const hourOptions: any = [];
    for (let i = 0; i < 24; i++) {
      for (let j = 0; j < 4; j++) {
        hourOptions.push(`${i.toString().padStart(2, "0")}:${j * 15}`);
      }
    }
    this.hourOptions = hourOptions;
    if (this.$route.params.focusTab) {
      this.tab = this.$route.params.focusTab;
    }
    this.refresh();
  }

  @Watch("tab")
  public updateTab() {
    if (this.tab === "presence") {
      this.loadPresence();
    } else if (this.tab === "informations") {
      this.loadInformations();
    }
  }

  public loadPresence() {
    this.loading = true;
    this.$store
      .dispatch("loadAreaPresence", {
        areaId: this.areaId,
      })
      .then((response: any) => {
        this.checkAreaPreferenceChanged = false;
        this.areaPresence = response;
        this.hasAreaPreferenceChanged = false;
        this.loading = false;
      })
      .catch((error: any) => {
        this.globalError(error);
        this.loading = false;
      });
  }

  public loadInformations() {
    this.loading = true;
    this.$store
      .dispatch("loadAreaInformations", {
        areaId: this.areaId,
        params: {
          with_building: true,
        },
      })
      .then((response: any) => {
        this.questions = response.questions;
        this.answers = response.answers;
        this.answersCompleted = response.completed;
        this.initialAnswers = JSON.parse(JSON.stringify(this.answers));
        this.buildingAnswers = response.building_answers;
        this.refreshMissingAnswers();
        this.loading = false;
      })
      .catch((error: any) => {
        this.globalError(error);
        this.loading = false;
      });
  }

  public answersChanged() {
    return deepEqual(this.answers, this.initialAnswers) === false;
  }

  public saveInformations() {
    if (!this.answersChanged()) {
      return;
    }
    this.$store
      .dispatch("saveAreaInformations", {
        areaId: this.areaId,
        payload: {
          answers: this.answers,
        },
      })
      .then((response: any) => {
        this.answersCompleted = response.completed;
        this.refreshMissingAnswers();
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      })
      .catch((error: any) => {
        this.globalError(error);
      });
  }

  public saveAreaPreferences() {
    this.$store
      .dispatch("editAreaPresence", {
        areaId: this.areaId,
        payload: this.areaPresence,
      })
      .then((response: any) => {
        this.areaPresence = response;
      })
      .catch((error: any) => {
        this.globalError(error);
      });
  }

  public hoursBefore(hourLimit: any) {
    return this.hourOptions.filter((hour: any) => {
      return hour < hourLimit;
    });
  }

  public hoursAfter(hourLimit: any) {
    return this.hourOptions.filter((hour: any) => {
      return hour > hourLimit;
    });
  }

  public onEditArea() {
    if (levenshtein(this.areaName, this.area.name) >= 3) {
      this.$q
        .dialog({
          title: this.$t("big_change_title"),
          message: this.$t("area_change_message"),
          stackButtons: true,
          cancel: {
            label: this.$t("area_change_cancel"),
            outline: true,
          },
          ok: {
            label: this.$t("big_change_ok"),
            flat: true,
            size: "m",
            dense: true,
          },
        })
        .onCancel(() => {
          this.areaName = this.area.name;
        })
        .onOk(() => {
          this._onEditArea();
        });
    } else {
      this._onEditArea();
    }
  }

  public _onEditArea() {
    const areaId: number = this.area.id;
    const payload: any = {
      name: this.areaName,
    };
    this.loading = true;
    this.$store
      .dispatch("editArea", {
        areaId,
        payload,
      })
      .then(() => {
        this.refresh();
      })
      .catch((error: any) => {
        this.loading = false;
        this.globalError(error);
      });
  }

  public deleteCurrentArea() {
    this.$q
      .dialog({
        title: this.$t("delete_title", [this.area.name]),
        message: this.$t("delete_message", [this.area.name]),
      })
      .onOk(() => {
        const areaId: number = this.area.id;
        this.$store
          .dispatch("deleteArea", {
            areaId,
          })
          .then(() => {
            this.$router.go(-1);
          })
          .catch((error: any) => {
            this.loading = false;
            this.globalError(error);
          });
      });
  }
}
